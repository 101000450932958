@font-face {
    font-family: regular;
    font-display: swap;
    src: url(../fonts/Roboto/Roboto-Regular.ttf);
}
@font-face {
    font-family: medium;
    font-display: swap;
    src: url(../fonts/Roboto/Roboto-Medium.ttf);
}
@font-face {
    font-family: monserrat-regular;
    font-display: swap;
    src: url(../fonts/Montserrat/Montserrat-Regular.ttf);
}
:root{
    --light-color-hex:#CCCCCC;
    --dark-color-hex:#669999;
    --main-green-hex:#00ade5;
    --background-color-hex:white;

    --fontsize-clamp-901:clamp(12px,1.5vw,2.2vw);
    --fontsize-clamp-900:clamp(15px,1.5vw,2.2vw);

    --fontfamily-regular-set:regular,'sans-serif';
    --fontfamily-medium-set:medium,'sans-serif';

    --container-indent-width:95%;
    --container-indent-height:98%;
}
/* Scroll bar */
*::-webkit-scrollbar {
    width: 8px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
}
*::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
*::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
*::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}

/* Animations */
@keyframes autoSlider {
    0%{
        transform: translateY(0%);
    }
    100%{
        transform: translateY(200%);
    }
}
/* General  */
.box-shadow{
    box-shadow: 0 .1em .3em 0 rgba(0, 0, 0, 0.1), 0 .1em .2em 0 rgba(0, 0, 0, 0.06);
}
.content-container{
    border-radius: .8rem;
    display: flex;
}
/* Scroll bar */
header{
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: var(--fontfamily-regular-set);
    align-items:center;
}
header>section{
    width: 100%;
}
    .header-navegation-section{
        width: 100%;
        height: 100%;    
    }   
    .header-navegation-section>input[type="checkbox"]#MobileNav{
        display: none;
    }
    .header-navegation-section>.mobilenav-checkbox-label{
        width: 50%;
    }
        .header-navegation-section>.header-homeselector-container{
            align-self: center;
            justify-self: center;
        }
            .header-navegation-section>.header-homeselector-container{
                display: flex;
                align-items: center;
            }
                .header-navegation-section>.header-homeselector-container>a{    
                    width: -moz-fit-content;
                    width: 56%;
                    margin: auto;
                    height: -moz-fit-content;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-size: .8em;
                    font-family:monserrat-regular;
                }   
                    .header-navegation-section>.header-homeselector-container>a>span{
                        display: flex;
                        border-radius: 5em;
                        margin-left: 2%;
                        margin-right: 2%;
                        overflow: hidden;
                    }
                        .header-navegation-section>.header-homeselector-container>a>span>img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                        
            .header-navegation-section>.header-search-bar-container,.header-navegation-section>.header-infobubble-container{
                display: flex;
                align-items: center;
            }
                .header-navegation-section>.header-search-bar-container>span{
                    margin-left: 4%;
                    width: fit-content;
                    height: fit-content;
                    display: flex;
                    justify-content:center;
                    align-items: center;
                    flex-direction: row;
                    border-radius: 5rem;
                }
                    .header-navegation-section>.header-search-bar-container>span>input[type="text"]{
                        padding: .2em;
                        margin-right: 1%;
                        text-indent: 2%;
                    }
                    .header-navegation-section>.header-search-bar-container>span>button{
                        padding: .2em;
                        margin-left: 1%;
                        margin-right: 1%;
                        width: 1.3em;
                        height: 1.3em;
                    }
            .header-navegation-section>.header-infobubble-container{
                display: flex;
                align-items: center;
                justify-content: center;
            }
                .header-navegation-section>.header-infobubble-container>div{
                    max-width: 50%;
                    width: fit-content;
                    height: fit-content;
                    display:flex ;
                    flex-direction: column;
                    
                }  
                    .header-navegation-section>.header-infobubble-container>div>p{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }   
                    .header-navegation-section>.header-infobubble-container>div>p:nth-child(1){
                            font-family: var(--fontfamily-medium-set);
                    }  
                .header-navegation-section>.header-infobubble-container>span{
                    display: flex;
                    border-radius: 5em;
                    margin-left: 2%;
                    margin-right: 2%;
                    overflow: hidden;
                    background-color: var(--main-green-hex);
                }
                    .header-navegation-section>.header-infobubble-container>span>img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
    
main{
    min-height: 80vh;
    display: flex;
    justify-content: center;
}
    main>div#Root{
        width: 100%;
        min-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        div#Root>.wrapper{
            width: 100%;
            height: 100%;
            display: flex;
        }
            div#Root>.wrapper>aside{
                height: 98%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
                div#Root>.wrapper>aside>ul{
                    width: 100%;
                    height: fit-content;
                    font-family: var(--fontfamily-regular-set);
                }
                    div#Root>.wrapper>aside>ul>a{
                        --text-color: var(--main-green-hex);
                        --bg-color: transparent;
                        padding-top: 0.6em;
                        padding-bottom: 0.6em;
                        margin-top: 1.5%;
                        margin-bottom: 1.5%;
                        padding-left: 15%;
                        width: 90%;
                        color: var(--text-color);
                        background-color: var(--bg-color);
                        border-top-right-radius: 5em;
                        border-bottom-right-radius: 5em;
                        display: inline-flex;
                        align-items: center;
                        font-size: .95em;
                        letter-spacing: .01em;
                    }
                    div#Root>.wrapper>aside>ul>a>span{
                        margin-right: 2%;
                        margin-left: 2%;
                        width: 1.3em;
                        height: 1.3em;
                    }
                        div#Root>.wrapper>aside>ul>a>span>svg{
                            width: 100%;
                            height: 100%;
                        }
                            div#Root>.wrapper>aside>ul>a>span>svg>path{
                                stroke: var(--text-color);
                                fill: var(--text-color);
                            }

                    div#Root>.wrapper>aside>ul>a.active-page{
                        --text-color:white;
                        --bg-color:var(--main-green-hex);
                        pointer-events: none;
                    }
                    div#Root>.wrapper>aside>ul>a.desable{
                        --text-color:#C6C6C6;
                        pointer-events: none;
                    }
                div#Root>.wrapper>aside>button{
                    width: fit-content;
                    height: fit-content;
                    padding-top: 0.3em;
                    padding-bottom: 0.3em;
                    margin-left: 15%;
                    display: inline-flex;
                    align-items: center;
                    color: #188379;
                    font-family: var(--fontfamily-regular-set);
                    font-size: .9em;
                }
                    div#Root>.wrapper>aside>button>span{
                        margin-right: 3%;
                        margin-left: 3%;
                        min-width: 1.3em;
                        min-height: 1.3em;
                        width: 1.3em;
                        height: 1.3em;
                    }
                        div#Root>.wrapper>aside>button>span>svg{
                            width: 100%;
                            height: 100%;
                        }
                            
                div#Root>.wrapper>aside>button.active-page{
                    color: var(--main-green-hex);
                }
                        div#Root>.wrapper>aside>button.active-page>span>svg>path{
                            fill:#2BD1C2;
                        }
            div#Root>.wrapper>.container{
                height: 98%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

@media only screen and (min-width:601px) {/*Largest devices */
html{
    font-size: var(--fontsize-clamp-901);
}
header{
    height: 15vh;
    font-size: var(--fontsize-clamp-901);
}
    .header-navegation-section{
        display: grid;
        grid-template-columns: 16% 64% 20%;
        grid-template-rows: 100%;
    }
        .header-navegation-section>.mobilenav-checkbox-label{
            display: none;
        }
            .header-navegation-section>.header-homeselector-container{
                width:100%;
                height: 100%;
            }
                .header-navegation-section>.header-homeselector-container>a>span{
                    max-height: 90%;
                    max-width: 60%;
                    width: 3em;
                    height: 3em;
                }
            .header-navegation-section>.header-search-bar-container,.header-navegation-section>.header-infobubble-container{
                height: 100%;
            }

            .header-navegation-section>.header-search-bar-container{
                width: 70%;
            }
                .header-navegation-section>.header-search-bar-container>span{
                    padding:.2em;
                }
                        .header-navegation-section>.header-search-bar-container>span>input[type="text"]{
                            max-width: 100%;
                            width: 35vw;
                            font-size: .8em;
                        }
            .header-navegation-section>.header-infobubble-container{
                width:100%;
                height: 100%;
            }
                .header-navegation-section>.header-infobubble-container>span{
                    max-height: 90%;
                    max-width: 60%;
                    width: 3em;
                    height: 3em;
                }
                    .header-navegation-section>.header-infobubble-container>div>p:nth-child(1){
                        font-size: .7em;
                    }   
                    .header-navegation-section>.header-infobubble-container>div>p:nth-child(2){
                        font-size: .65em;
                    }   
         
    div#Root>.wrapper{
        height: 80vh;
        flex-direction: row;
        align-items: center;
    }
            /* div#Root>.wrapper>aside{
                width: 16%;
            } */
            div#Root>.wrapper>.container{
                width: 84%;
            }
}
@media only screen and (min-width:768px) and (orientation:portrait) {/*Tablets */
   
    header{
        height: 15vh;
    }
 
}  
@media only screen and (max-width:600px) {/* Mobile */
html{
    font-size: var(--fontsize-clamp-900);
}
header{
    height: 10vh;
    position: relative;
    font-size: var(--fontsize-clamp-900);
}       
    .header-navegation-section {
        display: flex;
        flex-direction: row;
    }
        .header-navegation-section>.mobilenav-checkbox-label{
            display: flex;
            align-items: center;
            justify-content: center;
        }
            .header-navegation-section>.header-homeselector-container,.header-navegation-section>.header-search-bar-container{
                display: none;
            }  
            .header-navegation-section>.header-infobubble-container{
                width: 50%;
                height: 100%;
                display: flex;
                justify-content:flex-start;
            }
                .header-navegation-section>.header-infobubble-container>span{
                    display: none;
                }
                .header-navegation-section>.header-infobubble-container>div{
                    max-width: 100%;
                }
                    .header-navegation-section>.header-infobubble-container>div>p{
                        font-size: .9em;
                    }   

        div#Root>.wrapper{
            height: 80vh;
            flex-direction: column;
        }
            div#Root>.wrapper>aside{
                display: none;
            }
}
/* Large screen hasta width 1200px */
/* laptop hasta width 992px */
/* tablet hasta width 768px */
/* mobile hasta width 600px */